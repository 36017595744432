import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Market.module.sass";
import Loader from "../../../../components/Loader";
import { appLocalStorage } from "../../../../utils/localstorage";
import { setNftId } from "../../../../redux/settingsSlice";
import TextInput from "../../../../components/TextInput";
import Icon from "../../../../components/Icon";
import Row from "./Row";
import { showToast, isWalletAddressFormat, getEnsNameForAddress, getEnsAddressAcrossNetworks, shortenAddress } from "../../../../utils";
import { useMintPosition } from "../../../../customHooks/useMintPosition";
import useTransferNft from "../../../../customHooksWise/useTransferNft";
import { useAccount } from "wagmi";

const Market = ({
    items,
    allNfttPositions,
    reservedNftId,
    config,
    toggleRefetch,
    isTransferMode = false,
}) => {

    // const [chooseAll, setСhooseAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [buttonText, setButtonText] = useState(isTransferMode ? "Send Selected Position" : "Create New Position");
    const [transferAddress, setTransferAddress] = useState("");
    const [transactionHashLink, setTransactionHashLink] = useState("");

    const { address } = useAccount();

    const selectedFilters = [];
    // const [selectedFilters, setSelectedFilters] = useState([]);

    const dispatch = useDispatch();

    const {
        nftId,
    } = useSelector(
        (state) => state.settings
    );

    const handleChange = (id) => {
        dispatch(
            setNftId(id)
        );

        appLocalStorage.preferredNFT.update(
            id
        );

        showToast(
            `Position #${id} selected. Go back to dashboard.`,
            {
                type: "success",
            }
        )
    };

    const mintPosition = useMintPosition({
        config,
    });

    const transferNft = useTransferNft({
        contract: config?.positionNFTContract,
        chainId: config?.chainId
    });

    const handleSendPosition = async () => {
        if (!nftId) {
            showToast("Please select a position to send", {
                type: "info",
            });
            return;
        }

        if (parseInt(nftId) === parseInt(reservedNftId)) {
            showToast("This position is not yet minted. Please mint it first.", {
                type: "info",
            });
            return;
        }

        if (!transferAddress) {
            showToast("Please enter a recipient address", {
                type: "info",
            });
            return;
        }

        let resolvedAddress;

        if (isWalletAddressFormat(transferAddress)) {
            const res = {
                address: transferAddress,
                ensName: await getEnsNameForAddress(transferAddress),
            };
            if (res.address) {
                resolvedAddress = res.address;
            } else {
                showToast(
                    "Invalid ENS name or wallet address provided",
                    {
                        type: "warning",
                    }
                );
                return;
            }
        } else {
            const res = await getEnsAddressAcrossNetworks(
                transferAddress
            );

            if (res) {
                resolvedAddress = res;
            } else {
                showToast(
                    "Invalid ENS name or wallet address provided",
                    {
                        type: "warning",
                    }
                );
                return;
            }
        }

        if (resolvedAddress) {
            transferNft.transferNft({
                fromAddress: address,
                toAddress: resolvedAddress,
                tokenId: nftId,
                onConfirmation: () => {
                    setIsLoading(true);
                    setButtonText("Confirm In Wallet");
                }
            });
        }
    };

    const handleCreatePosition = () => {
        if (reservedNftId > 0) {
            showToast("Required: latest position must be minted", {
                type: "info",
            });
            return;
        }

        mintPosition.mintPosition({
            onConfirmation: () => {
                setIsLoading(true);
                setButtonText(
                    "Confirm In Wallet"
                );
            }
        });
    }

    useEffect(() => {
        if (mintPosition.error && isLoading && !isTransferMode) {
            setIsLoading(false);
            setButtonText(`Create New Position`);
        }

        if (mintPosition.isConfirming && isLoading && !isTransferMode) {
            setButtonText(`Creating Position...`);
        }

        if (mintPosition.isConfirmed && isLoading && !isTransferMode) {
            setIsLoading(false);
            const message = "Position created successfully!";

            showToast(message, {
                type: "success",
            });

            setButtonText(`Create New Position`);
            setIsLoading(false);
            toggleRefetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mintPosition]);

    useEffect(() => {
        if (transferNft.error && isLoading && isTransferMode) {
            setIsLoading(false);
            showToast(
                "Failed to transfer position: " + (transferNft.error?.message || "Unknown error"),
                {
                    type: "info",
                }
            );
            setButtonText("Send Selected Position");
        }

        if (transferNft.hasError && isLoading && isTransferMode) {
            setIsLoading(false);
            showToast(
                "Failed to transfer position: " + (transferNft.error?.message || "Unknown error"),
                {
                    type: "info",
                }
            );
            setButtonText("Send Selected Position");
        }

        if (transferNft.isConfirming && isLoading && isTransferMode) {
            setButtonText("Sending Position...");
            setTransactionHashLink(
                `${config?.linkBase}/tx/${transferNft.hash}`
            );
        }

        if (transferNft.isConfirmed && isLoading && isTransferMode) {
            const displayAddress = transferAddress.length === 42
                ? shortenAddress(transferAddress)
                : transferAddress;
            setIsLoading(false);
            showToast(
                `Position successfully transferred to ${displayAddress}`,
                {
                    type: "success",
                }
            );
            setButtonText("Send Selected Position");
            setTransferAddress("");
            toggleRefetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transferNft]);

    return (
        <div className={styles.market}>
            <div className={styles.table}>
                <div className={styles.row}>
                    <div className={styles.col}>
                    </div>
                    <div className={styles.col}>Position Id</div>
                    <div className={styles.col}>Status</div>
                    <div className={styles.col}>Net APY</div>
                    <div className={styles.col}>Lending</div>
                    <div className={styles.col}>Borrowing</div>
                    <div className={styles.col}>Borrow Limit Used</div>
                    <div className={styles.col}>Minted</div>
                </div>
                {/*{items.map((x, index) => (
                    <Row
                        item={x}
                        key={index}
                        up={items.length - index <= 2}
                        value={selectedFilters.includes(x.id)}
                        onChange={() => handleChange(x.id)}
                    />
                ))}*/}
                {allNfttPositions && allNfttPositions.map((x, index) => (
                    <Row
                        item={parseInt(x)}
                        key={index}
                        toggleRefetch={toggleRefetch}
                        reservedNftId={reservedNftId}
                        active={parseInt(nftId) === parseInt(x)}
                        up={items.length - index <= 2}
                        value={selectedFilters.includes(parseInt(x))}
                        onChanger={() => handleChange(parseInt(x))}
                    />
                ))}
            </div>
            <div className={styles.foot}>
                <div className={styles.switcher}>
                    {isTransferMode && (
                        <TextInput
                            className={styles.field}
                            name="transfer-address"
                            type="text"
                            value={transferAddress}
                            onChange={(e) => setTransferAddress(e.target.value)}
                            placeholder={"ENS name or wallet address"}
                            required
                            icon="profile-circle"
                        />
                    )}
                </div>
                {transactionHashLink && isTransferMode && (
                    <a
                        className={cn(
                            "button",
                            "button-stroke",
                            styles.transaction
                        )}
                        href={transactionHashLink}
                        target="_blank"
                        rel="noreferrer"
                    >
                        View Transaction Details
                    </a>
                )}
                <button
                    onClick={() => {
                        isTransferMode ? handleSendPosition() : handleCreatePosition();
                    }}
                    disabled={isLoading}
                    className={cn(
                        "button-stroke",
                        {
                            disabled: isLoading,
                        }
                    )}
                >
                    {isLoading ? (
                        <Loader className={styles.loader} />
                    ) : (
                        <Icon name={isTransferMode ? "arrow-top" : "plus"} />
                    )}
                    {buttonText}
                </button>
            </div>
        </div>
    );
};

export default Market;
